<template>
  <div class="serveBindingManage">
    <ds-header title=""></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目：">
        <a-select
          placeholder="请选择项目"
          v-model="searchForm.projectId"
          style="width: 200px"
        >
          <a-select-option v-for="item in projectList" :key="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!--发财树等级-->
      <a-form-item label="发财树等级：" v-if="type === 2">
        <a-select
          placeholder="请选择发财树等级"
          v-model="searchForm.prizeType"
          style="width: 200px"
        >
          <a-select-option v-for="item in moneyTreeGrade" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="参与日期" v-if="type === 2">
        <a-date-picker v-model="searchForm.joinDate" />
      </a-form-item>

      <a-form-item label="奖品名称：" v-if="this.type === 0">
        <a-input v-model.trim="searchForm.prizeName" placeholder="请输入奖品名称" class="inputClass" />
      </a-form-item>
      <a-form-item label="抽奖日期：" v-if="this.type === 0">
        <a-date-picker format="YYYY/MM/DD" v-model="searchForm.luckDraw" style="width: 200px" />
      </a-form-item>
      <a-form-item label="用户手机号：">
        <a-input v-model.trim="searchForm.phone" placeholder="请输入用户手机号" class="inputClass" />
      </a-form-item>
      <a-form-item label="用户ID：" v-if="type !== 2">
        <a-input v-model.trim="searchForm.userId" placeholder="请输入用户ID" class="inputClass" />
      </a-form-item>
      <a-form-item label="所属战队：" v-if="this.type === 1">
        <a-select
          placeholder="请选择所属战队"
          v-model="searchForm.procession"
          style="width: 200px"
        >
          <a-select-option v-for="item in teamList" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div class="backClass">
      <a-button @click="back">返回</a-button>
    </div>
    <div style="padding-top: 15px;">
      <a-button style="margin-right: 20px;" type="primary" @click="exportActivityData(1)">
        导出
      </a-button>
      <a-button type="primary" @click="exportActivityData(2)">
        <a-icon type="export" />
        导出全部
      </a-button>
    </div>
    <div style="margin-top: 10px" v-if="this.type === 1" v-show="teamsData.enthusiasmScore !== null || teamsData.sagacityScore !== null || teamsData.warmScore !== null">
      <span v-if="!searchForm.procession">
        {{ `当前总计数据：热情小i ${teamsData.enthusiasmScore ? teamsData.enthusiasmScore : 0}、睿智小i ${teamsData.sagacityScore ? teamsData.sagacityScore : 0}、温暖小i ${teamsData.warmScore ? teamsData.warmScore : 0}` }}
      </span>
      <span v-else>
        {{ `当前总计数据：${teamList.find(item => item.value === searchForm.procession).label}：${score}` }}
      </span>


    </div>
    <div style="padding-top: 15px">
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :rowKey="(record) => this.type === 1 ? record.userId : record.id"
        :columns="this.type === 1 ? table.pkColumns : this.type === 2 ? table.moneyTreeColumns : table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <template slot="operation" slot-scope="text, record">
            <a href="javascript:;" @click="view(record.id, record.userId)">查看</a>
        </template>
      </a-table>
    </div>
    <a-modal
      title="贡献详情"
      :visible="visible"
      @ok="handleOk"
      :footer="null"
      @cancel="handleCancel"
      width="40%"
      :destroyOnClose="true"
    >
      <Detail @close="handleCancel" :type="type" :currentClickUserId="currentClickUserId"/>
    </a-modal>
  </div>
</template>

<script>
import {
  getYearEndActivityDataList,
  exportActivityData,
  getPkActivityDataList,
  selectTeamData,
  exportPkActivityData,
  exportMoneyTreeData,
  getStartActivitiesData
} from "@/api/yearEndActivities";
import { projectName } from "@/api/modularManagement";
import moment from "moment";
import Detail from "../components/Detail.vue";
import { moneyTreeGrade } from '../../index'
import { moneyTreeColumns } from '../../index'

export default {
  name: "jackpotManagement",
  components: {Detail},
  data() {
    return {
      score: '',
      // 战队统计数据
      teamsData: {},
      // 发财树等级
      moneyTreeGrade,
      // 战队
      teamList: [
        {
          label: '热情小i',
          value: 'enthusiasm'
        },
        {
          label: '睿智小i',
          value: 'sagacity'
        },
        {
          label: '温暖小i',
          value: 'warm'
        }
      ],
      // 当前活动id
      currentActivityId: "",
      // 当前用户点击查看的用户id
      currentClickUserId: '',
      // 0: 年终活动。 1、战队pk、2、开工发财树， 3、开工红包
      type: 0,
      visible: false,
      searchForm: {
        projectId: undefined,
        // 发财树等级
        prizeType: undefined,
        // 参与日期
        joinDate: undefined,
        prizeName: "",
        luckDraw: undefined,
        phone: "",
        userId: "",
        // 所属战队
        procession: undefined
      },
      selectedRowKeys: [],
      projectList: [],
      table: {
        dataInfo: [],
        columns: [
          {
            title: "活动ID",
            dataIndex: "id",
            key: "id"
          },
          {
            title: "用户ID",
            dataIndex: "userId",
            key: "userId"
          },
          {
            title: "用户手机号",
            dataIndex: "phoneNumber",
            key: "phoneNumber"
          },
          {
            title: "参与活动项目",
            dataIndex: "projectName",
            key: "projectName"
          },
          {
            title: "奖品类型",
            dataIndex: "prizeType",
            customRender(text) {
              return text === 1 ? "优惠券" : text === 2 ? "积分" : text === 3 ? "现金" : "实物";
            }
          },
          {
            title: "奖品名称",
            dataIndex: "prizeTitle",
            key: "prizeTitle"
          },
          {
            title: "奖品抽取时间",
            dataIndex: "getDate",
            key: "getDate",
            customRender(text) {
              return moment(text).format("YYYY-MM-DD HH:mm:ss");
            }
          }
        ],
        pkColumns: [
          {
            title: "用户ID",
            dataIndex: "userId",
            key: "userId"
          },
          {
            title: "用户昵称",
            dataIndex: "nickname",
            key: "nickname"
          },
          {
            title: "所属项目",
            dataIndex: "projectName",
            key: "projectName"
          },
          {
            title: "所属战队",
            dataIndex: "processionName",
            key: "processionName"
          },
          {
            title: "手机号",
            dataIndex: "phoneNumber",
            key: "phoneNumber"
          },
          {
            title: "贡献积分总值",
            dataIndex: "integralValue",
            key: "integralValue"
          },
          {
            title: "排名",
            dataIndex: "rank",
            key: "rank"
          },
          {
            title: "加入战队时间",
            dataIndex: "joinTime",
            key: "joinTime"
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
          },
        ],
        moneyTreeColumns,
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          }
        }
      }
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  async created() {
    this.currentActivityId = this.$route.query.id;
    this.type = parseInt(this.$route.query.type)
    await this.getProjectList();
    await this.searchFormList();
  },
  methods: {
    checkoutData() {
      if(this.searchForm.procession) {
        if(this.searchForm.procession === 'enthusiasm') {
           this.score = this.teamsData.enthusiasmScore
        }
        if(this.searchForm.procession === 'sagacity') {
          this.score = this.teamsData.sagacityScore
        }
        if(this.searchForm.procession === 'warm') {
          this.score = this.teamsData.warmScore
        }
      }
    },
    // 获取所有项目
    async getProjectList() {
      projectName().then(res => {
        if (res.code === "200") {
          this.projectList = res.data.items;
        }
      });
    },
    // 搜索
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    // 重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = this.$options.data.call(this).searchForm;
      this.searchFormList();
    },

    // 列表
    async searchFormList() {
      const params = this.handleParams()
      let res = {}
      if(this.type === 1) {
        // 战队PK
        res = await getPkActivityDataList(params)
        await this.findTeamData()
      } else if(this.type === 0){
        res = await getYearEndActivityDataList(params)
      } else if(this.type === 2) {
        // 开工发财树活动
        res = await getStartActivitiesData(params)
      } else {
        // 开工红包
        res = await getYearEndActivityDataList(params)
      }
      const { data, code, msg } = res;
      if (code === "200") {
        if (this.type === 1) {
          this.table.dataInfo = data.items
          this.table.pagination.total = data.total
        } else {
          this.table.dataInfo = data.data;
          this.table.pagination.total = res.data.count;
        }
      } else {
        this.$message.error(msg);
      }
      if(this.searchForm.procession) {
        this.checkoutData()
      }
    },

    /**
     * 查询战队数据
     */
    async findTeamData() {
        const params = this.handleParams();
        const res = await selectTeamData(params);
        const { data, code } = res
        if(code === '200') {
            this.teamsData = data
        }
    },

    // 整理参数
    handleParams() {
      let params = {};
      params.activityId = this.currentActivityId;
      params.currentPage = this.table.pagination.current;
      params.pageSize = this.table.pagination.pageSize;
      params.projectId = this.searchForm.projectId ? this.searchForm.projectId : "";
      params.phoneNumber = this.searchForm.phone;
      params.userId = this.searchForm.userId;
      if(this.type === 1) {
        params.procession = this.searchForm.procession
      } else if(this.type === 0){
        params.prizeTitle = this.searchForm.prizeName;
        params.getDate = this.searchForm.luckDraw ? moment(this.searchForm.luckDraw).format('YYYY-MM-DD') : ''
      } else if(this.type === 2) {
          params.getDate = this.searchForm.joinDate ? moment(this.searchForm.joinDate).format('YYYY-MM-DD') : ''
          params.prizeType = this.searchForm.prizeType
      }
      return params;
    },

    // 处理时间 -- 转换为时间戳
    handleDate(data) {
      return moment(moment(data).format("YYYY-MM-DD")).valueOf() / 1000;
    },

    // 导出数据 flag === 1 ：按条件导出，flag === 2：导出当前活动全部
    exportActivityData(flag) {
      let params = {}
      if (flag === 1) {
        if (this.selectedRowKeys.length === 0) {
          this.$message.warning("请选择要导出的数据！")
          return false;
        }
        params.activityId = this.currentActivityId
        params.exportDataId =this.selectedRowKeys
      } else {
        params = this.handleParams();
      }
      // 种树活动
      if(this.type === 2) {

      } else {

      }
      // 种树活动
      if(this.type === 2) {
          exportMoneyTreeData(params).then(res => {
            const contentDispositionHeader = res.headers['content-disposition']
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDispositionHeader);

            if (matches !== null && matches[1]) {
              const filename = matches[1].replace(/['"]/g, '');
              const blob = new Blob([res.data]);
              const objectUrl = URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = objectUrl;
              a.setAttribute("download",decodeURIComponent(filename))
              a.click();
            }
          })
      } else if (this.type === 1) {
        exportPkActivityData(params).then(res => {
          const contentDispositionHeader = res.headers['content-disposition']
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDispositionHeader);

          if (matches !== null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, '');
            const blob = new Blob([res.data]);
            const objectUrl = URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = objectUrl;
            a.setAttribute("download",decodeURIComponent(filename))
            a.click();
          }
        })
      } else {
        exportActivityData(params).then(res => {
          if (res.status == 200) {
            const blob = new Blob([res.data]);
            const objectUrl = URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = objectUrl;
            a.setAttribute("download","活动数据.xlsx")
            a.click();
          } else {
            this.$message.error(res.msg)
          }

        });
      }
    },
    /**
     * 查看用户活动数据详情
     */
    view(id, userId) {
      this.currentClickUserId = userId
      this.visible = true
    },
    // 选中的
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
    /**
     * 确认弹窗
     */
    handleOk(e) {
      this.visible = false;
    },
    /**
     * 关闭弹窗
     */
    handleCancel(e) {
      this.visible = false;
    },
    back() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}

.action a {
  color: #1890ff;
  margin-right: 15px;
}

.action a:hover {
  color: #40a9ff;
}

.inputClass {
  width: 200px;
}

.imgContainer {
  width: 80px;
  height: 80px;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.backClass {
  float: right;
  margin-right: 20px;
}
</style>
